import styles from './footer.module.css'
import logotipo from '../logo.webp'
import { IoLogoFacebook, IoLogoInstagram, IoLogoLinkedin } from 'react-icons/io5'

export default function Footer() {
  return (
    <footer>
      <div className={styles.container}>
        <div className={styles.containerLinks}>
          <div className={styles.containerLogo}><img src={logotipo} alt="Descrição da imagem" /></div>
          <div className={styles.socialMedia}>
            <a target='_blank' href="https://www.linkedin.com/company/ecofixnaturalcapital/" rel="noreferrer"><IoLogoLinkedin /></a>
            <a target='_blank' href="https://www.facebook.com/ecofixnatural" rel="noreferrer"><IoLogoFacebook /></a>
            <a target='_blank' href="https://www.instagram.com/ecofixnatural/" rel="noreferrer"><IoLogoInstagram /></a>
          </div>
        </div>
        <div className={styles.containerCopyright}>
          <span>Copyright © 2023 Ecofix Natural Capital – Todos os direitos reservados – Desenvolvido por Tenshi Comunicação e Design.</span>
        </div>
      </div>
    </footer>
  )
}
