import './TabelaResultados.css';

const TabelaResultados = (props) => {
    console.log('data', props.rows, props.total)
    return (
        <div className='table-container'>
            <table className='results'>
                <caption className='caption-thead'>
                    {props.name}
                </caption>
                {props.hasHead && (
                    <thead>
                        <tr>
                            <th scope="col">SETOR</th>
                            <th scope="col">tCO2e</th>
                        </tr>
                    </thead>
                )}
                <tbody>
                    {props.rows?.map(row => {
                        return (
                            <tr>
                                <th className='row' scope="row">{row.name}</th>
                                <td>{row.data.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                            </tr>
                        )
                    })}
                </tbody>
                {props.total && (
                    <tfoot>
                        <tr>
                            <th scope="row" colspan="1">TOTAL</th>
                            <td>{props.total.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                        </tr>
                    </tfoot>
                )}
            </table>
        </div>
    );
};

export default TabelaResultados;