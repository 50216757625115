import React, { useState, useEffect } from 'react';
import api from '../services/api';

import { Chart } from "react-google-charts";

import TabelaResultados from './TabelaResultados';

import './Resultados.css';

const Resultados = () => {
  const [resultados, setResultados] = useState();

  useEffect(() => {
    api.get('get-resultados').then((response) => {
      setResultados(response.data.resultados);
    });
  }, []);

  if (resultados) {
    return (
      <div className='content'>
        <div className='container'>
          <TabelaResultados
            name="Emissões Anuais"
            hasHead
            rows={[
              { name: 'PECUÁRIA', data: resultados.emissoesAnuais.pecuaria.result ?? '-' },
              { name: 'AGRICULTURA', data: resultados.emissoesAnuais.agricultura.result ?? '-' },
              { name: 'DESMATAMENTO (1/20)', data: resultados.emissoesAnuais.desmatamento.result ?? '-' },
            ]}
            total={resultados.emissoesAnuais.total.result}
          />

        </div>

        <div className="container">
          <TabelaResultados
            name="Estoques C"
            hasHead
            rows={[
              { name: 'FLORESTA', data: resultados.estoquesC.floresta.result ?? '-' },
              { name: 'PASTAGEM', data: resultados.estoquesC.pastagem?.result ?? '-' },
              { name: 'SOLO', data: resultados.estoquesC.solo.result ?? '-' },
            ]}
            total={resultados.estoquesC.total.result}
          />

          <Chart
            chartType="PieChart"
            data={[
              ["Task", "Hours per Day"],
              ["FLORESTA", resultados.estoquesC.floresta?.result ?? 0],
              ["PASTAGEM", resultados.estoquesC.pastagem?.result ?? 0],
              ["SOLO", resultados.estoquesC.solo?.result ?? 0],
            ]}
            options={{
              title: "Emissões Agricultura",
            }}
            width={"80%"}
            height={"240px"}
          />
        </div>

        <div className='container'>
          <TabelaResultados
            name="AGRICULTURA"
            rows={[
              { name: 'PREPARO DO SOLO - DIESEL', data: resultados.agricultura.preparoSolo?.result ?? '-' },
              { name: 'N2O ADUBAÇÃO', data: resultados.agricultura.n2oAdubacao?.result ?? '-' },
              { name: 'CALAGEM', data: resultados.agricultura.calagem?.result ?? '-' },
              { name: 'OUTROS INSUMOS', data: resultados.agricultura.outrosInsumos?.result ?? '-' },
              { name: 'RESÍDUOS AGR. ALGODÃO', data: resultados.agricultura.residuosAlgodao?.result ?? '-' },
              { name: 'RESÍDUOS AGR. SOJA', data: resultados.agricultura.residuosSoja?.result ?? '-' },
              { name: 'RESÍDUOS AGR. MILHO', data: resultados.agricultura.residuosMilho?.result ?? '-' },
            ]}
            total={resultados.agricultura.total.result}
          />
          <Chart
            chartType="PieChart"
            data={[
              ["Task", "Hours per Day"],
              ["PREPARO DO SOLO - DIESEL", resultados.agricultura.preparoSolo?.result ?? 0],
              ["N2O ADUBAÇÃO", resultados.agricultura.n2oAdubacao?.result ?? 0],
              ["CALAGEM", resultados.agricultura.calagem?.result ?? 0],
              ["OUTROS INSUMOS", resultados.agricultura.outrosInsumos?.result ?? 0],
              ["RESÍDUOS AGR. ALGODÃO", resultados.agricultura.residuosAlgodao?.result ?? 0],
              ["RESÍDUOS AGR. SOJA", resultados.agricultura.residuosSoja?.result ?? 0],
              ["RESÍDUOS AGR. MILHO", resultados.agricultura.residuosMilho?.result ?? 0],
            ]}
            options={{
              title: "Emissões Agricultura",
            }}
            width={"80%"}
            height={"240px"}
          />
        </div>

        <div className="container">
          <TabelaResultados
            name="PECUÁRIA"
            rows={[
              { name: 'CH4 FERMENTAÇÃO ENTÉRICA', data: resultados.pecuaria.ch4Fermentacao?.result ?? '-' },
              { name: 'CH4 MANEJO DE DEJETOS', data: resultados.pecuaria.ch4ManejoDejetos?.result ?? '-' },
              { name: 'N2O DEJETOS PASTAGENS', data: resultados.pecuaria.n2oDejetosPastagens?.result ?? '-' },
              { name: 'DESMATAMENTO 1/20 anos', data: resultados.pecuaria.desmatamento?.result ?? '-' },
            ]}
          />

          <Chart
            chartType="PieChart"
            data={[
              ["Task", "Hours per Day"],
              ["CH4 FERMENTAÇÃO ENTÉRICA", resultados.pecuaria.ch4Fermentacao?.result ?? 0],
              ["CH4 MANEJO DE DEJETOS", resultados.pecuaria.ch4ManejoDejetos?.result ?? 0],
              ["N2O DEJETOS PASTAGENS", resultados.pecuaria.n2oDejetosPastagens?.result ?? 0],
              ["DESMATAMENTO 1/20 anos", resultados.pecuaria.desmatamento?.result ?? 0],
            ]}
            options={{
              title: "Emissões Pecuária",
            }}
            width={"80%"}
            height={"240px"}
          />
        </div>

        <div className="container">
          <TabelaResultados
            name="FLORESTA"
            rows={[
              { name: 'APP', data: resultados.floresta.app?.result ?? '-' },
              { name: 'RESERVA LEGAL', data: resultados.floresta.reservaLegal?.result ?? '-' },
              { name: 'EXCEDENTE RL', data: resultados.floresta.excedenteRL?.result ?? '-' },
            ]}
            total={resultados.floresta.total.result}
          />
        </div>
      </div>
    );
  }
};

export default Resultados;
