import React from 'react';
import ReactDOM from 'react-dom';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';

import App from './App';
import Formulario from './components/Formulario';
import Resultados from './components/Resultados';

const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    children: [
      {
        path: '/',
        element: <Formulario />
      },
      {
        path: '/resultados',
        element: <Resultados />
      }
    ]
  }
])

ReactDOM.render(
  <RouterProvider router={router} />,
  document.getElementById('root')
);
