import { BsInfoCircle } from 'react-icons/bs'
import style from './label.module.css'

export function Label({ children, toolTipContent }) {
  return (
    <span className={style.container}>
      {children}
      <BsInfoCircle title= {toolTipContent}/>
    </span>
  )
}