import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';


import api from '../services/api';

// Formulario.js
import './Formulario.css';
import { Label } from './label';


const Formulario = ({ onFormSubmit, setResultado }) => {
    const navigate = useNavigate();
    const [etapa, setEtapa] = useState(1);
    const [dados, setDados] = useState({
        etapa1: {},
        etapa2: {},
        etapa3: {},
        etapa4: {},
        etapa5: {},
        etapa6: {},
        etapa7: {},
        etapa8: {},
        etapa9: {},
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setDados((prevDados) => ({
            ...prevDados,
            [`etapa${etapa}`]: { ...prevDados[`etapa${etapa}`], [name]: value },
        }));
    };

    const handleNext = () => {
        if (etapa < 9) {
            setEtapa(etapa + 1);
            window.scrollTo(0, 0)
        }
    };

    const handlePrev = () => {
        if (etapa > 1) {
            setEtapa(etapa - 1);
            window.scrollTo(0, 0)
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault()
        try {
            await api.post('resultados', { dados });
        } catch (error) {
            alert('Houve um problema no processamento dos dados!')
            console.error('Erro ao enviar os dados do formulário:', error);
        }
        navigate("/resultados");
    };

    const testarSalvamento = () => {
        console.log('Dados Salvos:', dados);
    };

    testarSalvamento()

    return (

        <div className="containerForm">
            <form onSubmit={handleSubmit}>
                {etapa === 1 && (
                    <>
                        <h2>Informações Básicas</h2>

                        <div className='inputduplo'>
                            <label>
                                <Label toolTipContent='Digite seu Nome'>Nome:</Label>
                                <input
                                    type="text"
                                    name="nome"
                                    value={dados.etapa1.nome}
                                    onChange={handleChange}
                                    required
                                />
                            </label>
                            <label>
                                <Label toolTipContent=''>Instituição:</Label>
                                <input
                                    type="text"
                                    name="instituicao"
                                    value={dados.etapa1.instituicao}
                                    onChange={handleChange}
                                />
                            </label>
                        </div>

                        <div className='inputduplo'>
                            <label>
                                <Label toolTipContent=''>Função:</Label>
                                <input
                                    type="text"
                                    name="funcao"
                                    value={dados.etapa1.funcao}
                                    onChange={handleChange}
                                    required
                                />
                            </label>
                            <label>
                                <Label toolTipContent=''>Telefone:</Label>
                                <input
                                    type="text"
                                    name="telefone"
                                    value={dados.etapa1.telefone}
                                    onChange={handleChange}
                                    required
                                />
                            </label>
                        </div>

                        <div className='inputduplo'>
                            <label>
                                <Label toolTipContent=''>
                                    Email:
                                </Label>
                                <input
                                    type="email"
                                    name="email"
                                    value={dados.etapa1.email}
                                    onChange={handleChange}
                                    required
                                />
                            </label>

                            {/* Campos adicionais */}
                            <label>
                                <Label toolTipContent=''>
                                    Nome da Propriedade:
                                </Label>
                                <input
                                    type="text"
                                    name="nomePropriedade"
                                    value={dados.etapa1.nomePropriedade}
                                    onChange={handleChange}
                                    required
                                />
                            </label>
                        </div>

                        <div className='inputduplo'>
                            <label>
                                <Label toolTipContent=''>
                                    Proprietário:
                                </Label>
                                <input
                                    type="text"
                                    name="proprietario"
                                    value={dados.etapa1.proprietario}
                                    onChange={handleChange}
                                    required
                                />
                            </label>
                            <label>
                                <Label toolTipContent=''>
                                    Posse da Terra Esclarecida?
                                </Label>
                                <input
                                    type="text"
                                    name="posseTerraEsclarecida"
                                    value={dados.etapa1.posseTerraEsclarecida}
                                    onChange={handleChange}
                                    required
                                />
                            </label>
                        </div>

                        <div className='inputduplo'>
                            <label>
                                <Label toolTipContent=''>
                                    Há Conflitos Sociais?
                                </Label>
                                <input
                                    type="text"
                                    name="conflitosSociais"
                                    value={dados.etapa1.conflitosSociais}
                                    onChange={handleChange}
                                />
                            </label>
                            <label>
                                <Label toolTipContent=''>
                                    Instituição Responsável:
                                </Label>
                                <input
                                    type="text"
                                    name="instituicaoResponsavel"
                                    value={dados.etapa1.instituicaoResponsavel}
                                    onChange={handleChange}
                                />
                            </label>
                        </div>

                        <h2>
                            Localização Georeferenciada
                        </h2>
                        <div className='inputduplo'>
                            <label>
                                <Label toolTipContent=''>
                                    Latitude:
                                </Label>
                                <input
                                    type="text"
                                    name="latitude"
                                    value={dados.etapa1.latitude}
                                    onChange={handleChange}
                                />
                            </label>
                            <label>
                                <Label toolTipContent=''>
                                    Longitude:
                                </Label>
                                <input
                                    type="text"
                                    name="longitude"
                                    value={dados.etapa1.longitude}
                                    onChange={handleChange}
                                />
                            </label>
                        </div>

                        <label>
                            <Label toolTipContent=''>
                                Planta de Uso e Ocupação do Solo:
                            </Label>
                            <input
                                type="text"
                                name="plantaUsoOcupacaoSolo"
                                value={dados.etapa1.plantaUsoOcupacaoSolo}
                                onChange={handleChange}
                            />
                        </label>

                        <h2>Informações Gerais</h2>

                        <div className='inputduplo'>
                            {/* Reserva Legal */}
                            <label>
                                <Label toolTipContent=''>
                                    Reserva Legal (Descrição):
                                </Label>
                                <input
                                    type="text"
                                    name="reservaLegalDescricao"
                                    value={dados.etapa1.reservaLegalDescricao}
                                    onChange={handleChange}
                                />
                            </label>
                            <label>
                                <Label toolTipContent=''>
                                    Reserva Legal (Área ha):
                                </Label>
                                <input
                                    type="text"
                                    name="reservaLegalArea"
                                    value={dados.etapa1.reservaLegalArea}
                                    onChange={handleChange}
                                />
                            </label>
                        </div>

                        <div className='inputduplo'>
                            {/* APP */}
                            <label>
                                <Label toolTipContent=''>
                                    APP (Descrição):
                                </Label>
                                <input
                                    type="text"
                                    name="appDescricao"
                                    value={dados.etapa1.appDescricao}
                                    onChange={handleChange}
                                />
                            </label>
                            <label>
                                <Label toolTipContent=''>
                                    APP (Área ha):
                                </Label>
                                <input
                                    type="text"
                                    name="appArea"
                                    value={dados.etapa1.appArea}
                                    onChange={handleChange}
                                />
                            </label>
                        </div>

                        <div className='inputduplo'>
                            {/* Vegetação Nativa */}
                            <label>
                                <Label toolTipContent=''>
                                    Vegetação Nativa (Descrição):
                                </Label>
                                <input
                                    type="text"
                                    name="vegetacaoNativaDescricao"
                                    value={dados.etapa1.vegetacaoNativaDescricao}
                                    onChange={handleChange}
                                />
                            </label>
                            <label>
                                <Label toolTipContent=''>
                                    Vegetação Nativa (Área ha):
                                </Label>
                                <input
                                    type="text"
                                    name="vegetacaoNativaArea"
                                    value={dados.etapa1.vegetacaoNativaArea}
                                    onChange={handleChange}
                                />
                            </label>
                        </div>

                        <div className='inputduplo'>
                            {/* CAR */}
                            <label>
                                <Label toolTipContent=''>
                                    CAR (Descrição):
                                </Label>
                                <input
                                    type="text"
                                    name="carDescricao"
                                    value={dados.etapa1.carDescricao}
                                    onChange={handleChange}
                                />
                            </label>
                            <label>
                                <Label toolTipContent=''>
                                    CAR (Área ha):
                                </Label>
                                <input
                                    type="text"
                                    name="carArea"
                                    value={dados.etapa1.carArea}
                                    onChange={handleChange}
                                />
                            </label>
                        </div>

                        <div className='inputduplo'>
                            {/* LAR */}
                            <label>
                                <Label toolTipContent=''>
                                    LAR (Descrição):
                                </Label>
                                <input
                                    type="text"
                                    name="larDescricao"
                                    value={dados.etapa1.larDescricao}
                                    onChange={handleChange}
                                />
                            </label>
                            <label>
                                <Label toolTipContent=''>
                                    LAR (Área ha):
                                </Label>
                                <input
                                    type="text"
                                    name="larArea"
                                    value={dados.etapa1.larArea}
                                    onChange={handleChange}
                                />
                            </label>
                        </div>

                        <div className='inputduplo'>
                            {/* Plano de Recomposição */}
                            <label>
                                <Label toolTipContent=''>
                                    Plano de Recomposição (Descrição):
                                </Label>
                                <input
                                    type="text"
                                    name="planoRecomposicaoDescricao"
                                    value={dados.etapa1.planoRecomposicaoDescricao}
                                    onChange={handleChange}
                                />
                            </label>
                            <label>
                                <Label toolTipContent=''>
                                    Plano de Recomposição (Área ha):
                                </Label>
                                <input
                                    type="text"
                                    name="planoRecomposicaoArea"
                                    value={dados.etapa1.planoRecomposicaoArea}
                                    onChange={handleChange}
                                />
                            </label>
                        </div>

                        <div className='inputduplo'>
                            {/* Objeto de Autuação Ambiental */}
                            <label>
                                <Label toolTipContent='Descreva o Objeto de Autuação Ambiental.'>Objeto de Autuação Ambiental (Descrição):</Label>
                                <input
                                    type="text"
                                    name="objetoAutuacaoDescricao"
                                    value={dados.etapa1.objetoAutuacaoDescricao}
                                    onChange={handleChange}
                                />
                            </label>
                            <label>
                                <Label toolTipContent=''>
                                    Objeto de Autuação Ambiental (Área ha):
                                </Label>
                                <input
                                    type="text"
                                    name="objetoAutuacaoArea"
                                    value={dados.etapa1.objetoAutuacaoArea}
                                    onChange={handleChange}
                                />
                            </label>
                        </div>

                        <div className='inputtriplo'>
                            {/* Atividade Produtiva - Agricultura */}
                            <label>
                                <Label toolTipContent=''>
                                    Agricultura (Atividade):
                                </Label>
                                <input
                                    type="text"
                                    name="agriculturaNome"
                                    value={dados.etapa1.agriculturaNome}
                                    onChange={handleChange}
                                />
                            </label>
                            <label>
                                <Label toolTipContent=''>
                                    Agricultura (Área ha):
                                </Label>
                                <input
                                    type="text"
                                    name="agriculturaArea"
                                    value={dados.etapa1.agriculturaArea}
                                    onChange={handleChange}
                                />
                            </label>
                            <label>
                                <Label toolTipContent=''>
                                    Agricultura (Produção Ton):
                                </Label>
                                <input
                                    type="text"
                                    name="agriculturaProducao"
                                    value={dados.etapa1.agriculturaProducao}
                                    onChange={handleChange}
                                />
                            </label>
                        </div>

                        <div className='inputtriplo'>
                            {/* Atividade Produtiva - Pecuária */}
                            <label>
                                <Label toolTipContent=''>
                                    Pecuária (Atividade):
                                </Label>
                                <input
                                    type="text"
                                    name="pecuariaNome"
                                    value={dados.etapa1.pecuariaNome}
                                    onChange={handleChange}
                                />
                            </label>
                            <label>
                                <Label toolTipContent=''>
                                    Pecuária (Área ha):
                                </Label>
                                <input
                                    type="text"
                                    name="pecuariaArea"
                                    value={dados.etapa1.pecuariaArea}
                                    onChange={handleChange}
                                />
                            </label>
                            <label>
                                <Label toolTipContent=''>
                                    Pecuária (Produção Ton):
                                </Label>
                                <input
                                    type="text"
                                    name="pecuariaProducao"
                                    value={dados.etapa1.pecuariaProducao}
                                    onChange={handleChange}
                                />
                            </label>
                        </div>


                        <div className='inputtriplo'>
                            {/* Atividade Produtiva - Floresta */}
                            <label>
                                <Label toolTipContent=''>
                                    Floresta (Atividade):
                                </Label>
                                <input
                                    type="text"
                                    name="florestaNome"
                                    value={dados.etapa1.florestaNome}
                                    onChange={handleChange}
                                />
                            </label>
                            <label>
                                <Label toolTipContent=''>
                                    Floresta (Área ha):
                                </Label>
                                <input
                                    type="text"
                                    name="florestaArea"
                                    value={dados.etapa1.florestaArea}
                                    onChange={handleChange}
                                />
                            </label>
                            <label>
                                <Label toolTipContent=''>
                                    Floresta (Produção Ton):
                                </Label>
                                <input
                                    type="text"
                                    name="florestaProducao"
                                    value={dados.etapa1.florestaProducao}
                                    onChange={handleChange}
                                />
                            </label>
                        </div>

                        {/* Área Total */}
                        <label>
                            <Label toolTipContent=''>
                                Área Total:
                            </Label>
                            <input
                                type="text"
                                name="areaTotal"
                                value={dados.etapa1.areaTotal}
                                onChange={handleChange}
                            />
                        </label>

                        {/* Área Total Informada (CAR) */}
                        <label>
                            <Label toolTipContent=''>
                                Área Total Informada (CAR):
                            </Label>
                            <input
                                type="text"
                                name="areaTotalCAR"
                                value={dados.etapa1.areaTotalCAR}
                                onChange={handleChange}
                            />
                        </label>


                    </>
                )}

                {etapa === 2 && (
                    <>
                        <h2>Operações de Plantio</h2>
                        {/* AGRICULTURA COMBUSTÍVEL */}
                        <label>
                            <Label toolTipContent=''>
                                Consumo Óleo Diesel:
                            </Label>
                            <input
                                type="number"
                                name="consumoOleoDiesel"
                                value={dados.etapa2.consumoOleoDiesel}
                                onChange={handleChange}
                            />
                        </label>

                        <div className='inputduplo'>
                            <label>
                                <Label toolTipContent=''>
                                    Roçagem Área(ha):
                                </Label>
                                <input
                                    type="number"
                                    name="roçagem"
                                    value={dados.etapa2.roçagem}
                                    onChange={handleChange}
                                />
                            </label>
                            <label>
                                <Label toolTipContent=''>
                                    Número de operações (Roçagem):
                                </Label>
                                <input
                                    type="number"
                                    name="numOperacoesRoçagem"
                                    value={dados.etapa2.numOperacoesRoçagem}
                                    onChange={handleChange}
                                />
                            </label>
                        </div>

                        <div className='inputduplo'>
                            <label>
                                <Label toolTipContent=''>
                                    Picagem de palha Área(ha):
                                </Label>
                                <input
                                    type="number"
                                    name="picagemPalha"
                                    value={dados.etapa2.picagemPalha}
                                    onChange={handleChange}
                                />
                            </label>
                            <label>
                                <Label toolTipContent=''>
                                    Número de operações (Picagem de palha):
                                </Label>
                                <input
                                    type="number"
                                    name="numOperacoesPicagemPalha"
                                    value={dados.etapa2.numOperacoesPicagemPalha}
                                    onChange={handleChange}
                                />
                            </label>
                        </div>

                        <div className='inputduplo'>
                            <label>
                                <Label toolTipContent=''>
                                    Aração Área(ha):
                                </Label>
                                <input
                                    type="number"
                                    name="aracao"
                                    value={dados.etapa2.aracao}
                                    onChange={handleChange}
                                />
                            </label>
                            <label>
                                <Label toolTipContent=''>
                                    Número de operações (Aração):
                                </Label>
                                <input
                                    type="number"
                                    name="numOperacoesAracao"
                                    value={dados.etapa2.numOperacoesAracao}
                                    onChange={handleChange}
                                />
                            </label>
                        </div>

                        <div className='inputduplo'>
                            <label>
                                <Label toolTipContent=''>
                                    1ª Gradagem Área(ha):
                                </Label>
                                <input
                                    type="number"
                                    name="gradagem1"
                                    value={dados.etapa2.gradagem1}
                                    onChange={handleChange}
                                />
                            </label>
                            <label>
                                <Label toolTipContent=''>
                                    Número de operações (1ª Gradagem):
                                </Label>
                                <input
                                    type="number"
                                    name="numOperacoesGradagem1"
                                    value={dados.etapa2.numOperacoesGradagem1}
                                    onChange={handleChange}
                                />
                            </label>
                        </div>

                        <div className='inputduplo'>
                            <label>
                                <Label toolTipContent=''>
                                    Aplicação de Herbicida Área(ha):
                                </Label>
                                <input
                                    type="number"
                                    name="herbicida"
                                    value={dados.etapa2.herbicida}
                                    onChange={handleChange}
                                />
                            </label>
                            <label>
                                <Label toolTipContent=''>
                                    Número de operações (Aplicação de Herbicida):
                                </Label>
                                <input
                                    type="number"
                                    name="numOperacoesHerbicida"
                                    value={dados.etapa2.numOperacoesHerbicida}
                                    onChange={handleChange}
                                />
                            </label>
                        </div>

                        <div className='inputduplo'>
                            <label>
                                <Label toolTipContent=''>
                                    2ª Gradagem Área(ha):
                                </Label>
                                <input
                                    type="number"
                                    name="gradagem2"
                                    value={dados.etapa2.gradagem2}
                                    onChange={handleChange}
                                />
                            </label>
                            <label>
                                <Label toolTipContent=''>
                                    Número de operações (2ª Gradagem):
                                </Label>
                                <input
                                    type="number"
                                    name="numOperacoesGradagem2"
                                    value={dados.etapa2.numOperacoesGradagem2}
                                    onChange={handleChange}
                                />
                            </label>
                        </div>

                        <div className='inputduplo'>
                            <label>
                                <Label toolTipContent=''>
                                    3ª Gradagem Área(ha):
                                </Label>
                                <input
                                    type="number"
                                    name="gradagem3"
                                    value={dados.etapa2.gradagem3}
                                    onChange={handleChange}
                                />
                            </label>
                            <label>
                                <Label toolTipContent=''>
                                    Número de operações (3ª Gradagem):
                                </Label>
                                <input
                                    type="number"
                                    name="numOperacoesGradagem3"
                                    value={dados.etapa2.numOperacoesGradagem3}
                                    onChange={handleChange}
                                />
                            </label>
                        </div>

                        <div className='inputduplo'>
                            <label>
                                <Label toolTipContent=''>
                                    Subsolagem Área(ha):
                                </Label>
                                <input
                                    type="number"
                                    name="subsolagem"
                                    value={dados.etapa2.subsolagem}
                                    onChange={handleChange}
                                />
                            </label>
                            <label>
                                <Label toolTipContent=''>
                                    Número de operações (Subsolagem):
                                </Label>
                                <input
                                    type="number"
                                    name="numOperacoesSubsolagem"
                                    value={dados.etapa2.numOperacoesSubsolagem}
                                    onChange={handleChange}
                                />
                            </label>
                        </div>

                        <div className='inputduplo'>
                            <label>
                                <Label toolTipContent=''>
                                    Escarificação Área(ha):
                                </Label>
                                <input
                                    type="number"
                                    name="escarificacao"
                                    value={dados.etapa2.escarificacao}
                                    onChange={handleChange}
                                />
                            </label>
                            <label>
                                <Label toolTipContent=''>
                                    Número de operações (Escarificação):
                                </Label>
                                <input
                                    type="number"
                                    name="numOperacoesEscarificacao"
                                    value={dados.etapa2.numOperacoesEscarificacao}
                                    onChange={handleChange}
                                />
                            </label>
                        </div>

                        <div className='inputduplo'>
                            <label>
                                <Label toolTipContent=''>
                                    Calagem Área(ha):
                                </Label>
                                <input
                                    type="number"
                                    name="calagem"
                                    value={dados.etapa2.calagem}
                                    onChange={handleChange}
                                />
                            </label>
                            <label>
                                <Label toolTipContent=''>
                                    Número de operações (Calagem):
                                </Label>
                                <input
                                    type="number"
                                    name="numOperacoesCalagem"
                                    value={dados.etapa2.numOperacoesCalagem}
                                    onChange={handleChange}
                                />
                            </label>
                        </div>

                        <div className='inputduplo'>
                            <label>
                                <Label toolTipContent=''>
                                    Adubação Área(ha):
                                </Label>
                                <input
                                    type="number"
                                    name="adubacao"
                                    value={dados.etapa2.adubacao}
                                    onChange={handleChange}
                                />
                            </label>
                            <label>
                                <Label toolTipContent=''>
                                    Número de operações (Adubação):
                                </Label>
                                <input
                                    type="number"
                                    name="numOperacoesAdubacao"
                                    value={dados.etapa2.numOperacoesAdubacao}
                                    onChange={handleChange}
                                />
                            </label>
                        </div>

                        <div className='inputduplo'>
                            <label>
                                <Label toolTipContent=''>
                                    Plantio Área(ha):
                                </Label>
                                <input
                                    type="number"
                                    name="plantio"
                                    value={dados.etapa2.plantio}
                                    onChange={handleChange}
                                />
                            </label>
                            <label>
                                <Label toolTipContent=''>
                                    Número de operações (Plantio):
                                </Label>
                                <input
                                    type="number"
                                    name="numOperacoesPlantio"
                                    value={dados.etapa2.numOperacoesPlantio}
                                    onChange={handleChange}
                                />
                            </label>
                        </div>

                        <div className='inputduplo'>
                            <label>
                                <Label toolTipContent=''>
                                    Plantio e Pulverização Área(ha):
                                </Label>
                                <input
                                    type="number"
                                    name="plantioPulverizacao"
                                    value={dados.etapa2.plantioPulverizacao}
                                    onChange={handleChange}
                                />
                            </label>
                            <label>
                                <Label toolTipContent=''>
                                    Número de operações (Plantio e Pulverização):
                                </Label>
                                <input
                                    type="number"
                                    name="numOperacoesPlantioPulverizacao"
                                    value={dados.etapa2.numOperacoesPlantioPulverizacao}
                                    onChange={handleChange}
                                />
                            </label>
                        </div>

                        <div className='inputduplo'>
                            <label>
                                <Label toolTipContent=''>
                                    Aplicação Defensivos Área(ha):
                                </Label>
                                <input
                                    type="number"
                                    name="aplicacaoDefensivos"
                                    value={dados.etapa2.aplicacaoDefensivos}
                                    onChange={handleChange}
                                />
                            </label>
                            <label>
                                <Label toolTipContent=''>
                                    Número de operações (Aplicação Defensivos):
                                </Label>
                                <input
                                    type="number"
                                    name="numOperacoesAplicacaoDefensivos"
                                    value={dados.etapa2.numOperacoesAplicacaoDefensivos}
                                    onChange={handleChange}
                                />
                            </label>
                        </div>

                        <div className='inputduplo'>
                            <label>
                                <Label toolTipContent=''>
                                    Controle de pragas e doenças Área(ha):
                                </Label>
                                <input
                                    type="number"
                                    name="controlePragasDoencas"
                                    value={dados.etapa2.controlePragasDoencas}
                                    onChange={handleChange}
                                />
                            </label>
                            <label>
                                <Label toolTipContent=''>
                                    Número de operações (Controle de pragas e doenças):
                                </Label>
                                <input
                                    type="number"
                                    name="numOperacoesControlePragasDoencas"
                                    value={dados.etapa2.numOperacoesControlePragasDoencas}
                                    onChange={handleChange}
                                />
                            </label>
                        </div>

                        <div className='inputduplo'>
                            <label>
                                <Label toolTipContent=''>
                                    1º Cultivo Área(ha):
                                </Label>
                                <input
                                    type="number"
                                    name="cultivo1"
                                    value={dados.etapa2.cultivo1}
                                    onChange={handleChange}
                                />
                            </label>
                            <label>
                                <Label toolTipContent=''>
                                    Número de operações (1º Cultivo):
                                </Label>
                                <input
                                    type="number"
                                    name="numOperacoesCultivo1"
                                    value={dados.etapa2.numOperacoesCultivo1}
                                    onChange={handleChange}
                                />
                            </label>
                        </div>

                        <div className='inputduplo'>
                            <label>
                                <Label toolTipContent=''>
                                    2º Cultivo Área(ha):
                                </Label>
                                <input
                                    type="number"
                                    name="cultivo2"
                                    value={dados.etapa2.cultivo2}
                                    onChange={handleChange}
                                />
                            </label>
                            <label>
                                <Label toolTipContent=''>
                                    Número de operações (2º Cultivo):
                                </Label>
                                <input
                                    type="number"
                                    name="numOperacoesCultivo2"
                                    value={dados.etapa2.numOperacoesCultivo2}
                                    onChange={handleChange}
                                />
                            </label>
                        </div>

                        <div className='inputduplo'>
                            <label>
                                <Label toolTipContent=''>
                                    Colheita Área(ha):
                                </Label>
                                <input
                                    type="number"
                                    name="colheita"
                                    value={dados.etapa2.colheita}
                                    onChange={handleChange}
                                />
                            </label>
                            <label>
                                <Label toolTipContent=''>
                                    Número de operações (Colheita):
                                </Label>
                                <input
                                    type="number"
                                    name="numOperacoesColheita"
                                    value={dados.etapa2.numOperacoesColheita}
                                    onChange={handleChange}
                                />
                            </label>
                        </div>





                    </>
                )}

                {etapa === 3 && (
                    <>
                        { /* Parte 1 - Fórmula NPK */}

                        <h2>Fórmula NPK</h2>
                        <div className='inputquinto'>
                            <label>
                                <Label toolTipContent=''>
                                    N:
                                </Label>
                                <input
                                    type="number"
                                    name="n1"
                                    value={dados.etapa3.n1}
                                    onChange={handleChange}
                                />
                            </label>

                            <label>
                                <Label toolTipContent=''>
                                    P:
                                </Label>
                                <input
                                    type="number"
                                    name="p1"
                                    value={dados.etapa3.p1}
                                    onChange={handleChange}
                                />
                            </label>

                            <label>
                                <Label toolTipContent=''>
                                    K:
                                </Label>
                                <input
                                    type="number"
                                    name="k1"
                                    value={dados.etapa3.k1}
                                    onChange={handleChange}
                                />
                            </label>

                            <label>
                                <Label toolTipContent=''>
                                    %n:
                                </Label>
                                <input
                                    type="number"
                                    name="percentN1"
                                    value={dados.etapa3.percentN1}
                                    onChange={handleChange}
                                />
                            </label>

                            <label>
                                <Label toolTipContent=''>
                                    Dosagem (kg/ha):
                                </Label>
                                <input
                                    type="number"
                                    name="dosagemNPK1"
                                    value={dados.etapa3.dosagemNPK1}
                                    onChange={handleChange}
                                />
                            </label>
                        </div>

                        <div className='inputquinto'>
                            <label>
                                <Label toolTipContent=''>
                                    N:
                                </Label>
                                <input
                                    type="number"
                                    name="n2"
                                    value={dados.etapa3.n2}
                                    onChange={handleChange}
                                />
                            </label>

                            <label>
                                <Label toolTipContent=''>
                                    P:
                                </Label>
                                <input
                                    type="number"
                                    name="p2"
                                    value={dados.etapa3.p2}
                                    onChange={handleChange}
                                />
                            </label>

                            <label>
                                <Label toolTipContent=''>
                                    K:
                                </Label>
                                <input
                                    type="number"
                                    name="k2"
                                    value={dados.etapa3.k2}
                                    onChange={handleChange}
                                />
                            </label>

                            <label>
                                <Label toolTipContent=''>
                                    %n:
                                </Label>
                                <input
                                    type="number"
                                    name="percentN2"
                                    value={dados.etapa3.percentN2}
                                    onChange={handleChange}
                                />
                            </label>

                            <label>
                                <Label toolTipContent=''>
                                    Dosagem (kg/ha):
                                </Label>
                                <input
                                    type="number"
                                    name="dosagemNPK2"
                                    value={dados.etapa3.dosagemNPK2}
                                    onChange={handleChange}
                                />
                            </label>
                        </div>

                        { /* Repetir os campos acima para as demais linhas de Fórmula NPK */}

                        { /* Ureia */}
                        <h2>Uréia</h2>
                        <div className='inputtriplo'>
                            <label>
                                <Label toolTipContent=''>
                                    Área(ha):
                                </Label>
                                <input
                                    type="number"
                                    name="areaUreia1"
                                    value={dados.etapa3.areaUreia1}
                                    onChange={handleChange}
                                />
                            </label>

                            <label>
                                <Label toolTipContent=''>
                                    %n:
                                </Label>
                                <input
                                    type="number"
                                    name="percentNUreia1"
                                    value={dados.etapa3.percentNUreia1}
                                    onChange={handleChange}
                                />
                            </label>

                            <label>
                                <Label toolTipContent=''>
                                    Dosagem(kg/ha):
                                </Label>
                                <input
                                    type="number"
                                    name="dosagemUreia1"
                                    value={dados.etapa3.dosagemUreia1}
                                    onChange={handleChange}
                                />
                            </label>
                        </div>
                        <div className='inputtriplo'>
                            <label>
                                <Label toolTipContent=''>
                                    Área(ha):
                                </Label>
                                <input
                                    type="number"
                                    name="areaUreia2"
                                    value={dados.etapa3.areaUreia2}
                                    onChange={handleChange}
                                />
                            </label>

                            <label>
                                <Label toolTipContent=''>
                                    %n:
                                </Label>
                                <input
                                    type="number"
                                    name="percentNUreia2"
                                    value={dados.etapa3.percentNUreia2}
                                    onChange={handleChange}
                                />
                            </label>

                            <label>
                                <Label toolTipContent=''>
                                    Dosagem(kg/ha):
                                </Label>
                                <input
                                    type="number"
                                    name="dosagemUreia2"
                                    value={dados.etapa3.dosagemUreia2}
                                    onChange={handleChange}
                                />
                            </label>
                        </div>

                        { /* Repetir os campos acima para as demais linhas de Ureia */}

                        { /* Sulfato de amônio */}
                        <h2>Sulfato de amônio</h2>
                        <div className='inputtriplo'>
                            <label>
                                <Label toolTipContent=''>
                                    Área(ha):
                                </Label>
                                <input
                                    type="number"
                                    name="areaSulfato1"
                                    value={dados.etapa3.areaSulfato1}
                                    onChange={handleChange}
                                />
                            </label>

                            <label>
                                <Label toolTipContent=''>
                                    %n:
                                </Label>
                                <input
                                    type="number"
                                    name="percentNSulfato1"
                                    value={dados.etapa3.percentNSulfato1}
                                    onChange={handleChange}
                                />
                            </label>

                            <label>
                                <Label toolTipContent=''>
                                    Dosagem(kg/ha):
                                </Label>
                                <input
                                    type="number"
                                    name="dosagemSulfato1"
                                    value={dados.etapa3.dosagemSulfato1}
                                    onChange={handleChange}
                                />
                            </label>
                        </div>
                        <div className='inputtriplo'>
                            <label>
                                <Label toolTipContent=''>
                                    Área(ha):
                                </Label>
                                <input
                                    type="number"
                                    name="areaSulfato2"
                                    value={dados.etapa3.areaSulfato2}
                                    onChange={handleChange}
                                />
                            </label>

                            <label>
                                <Label toolTipContent=''>
                                    %n:
                                </Label>
                                <input
                                    type="number"
                                    name="percentNSulfato2"
                                    value={dados.etapa3.percentNSulfato2}
                                    onChange={handleChange}
                                />
                            </label>

                            <label>
                                <Label toolTipContent=''>
                                    Dosagem(kg/ha):
                                </Label>
                                <input
                                    type="number"
                                    name="dosagemSulfato2"
                                    value={dados.etapa3.dosagemSulfato2}
                                    onChange={handleChange}
                                />
                            </label>
                        </div>

                        { /* Repetir os campos acima para as demais linhas de Sulfato de amônio */}



                    </>
                )}

                {etapa === 4 && (
                    <>
                        {/* Calcário - Calagem */}
                        <h2>Calcário - Calagem</h2>
                        <div className='inputduplo'>
                            <label>
                                <Label toolTipContent=''>
                                    Área(ha):
                                </Label>
                                <input
                                    type="text"
                                    name="area1"
                                    value={dados.etapa4.area1}
                                    onChange={handleChange}
                                />
                            </label>
                            <label>
                                <Label toolTipContent=''>
                                    Dosagem(kg/ha):
                                </Label>
                                <input
                                    type="text"
                                    name="dosagem1"
                                    value={dados.etapa4.dosagem1}
                                    onChange={handleChange}
                                />
                            </label>
                        </div>

                        <div className='inputduplo'>
                            <label>
                                <Label toolTipContent=''>
                                    Área(ha):
                                </Label>
                                <input
                                    type="text"
                                    name="area2"
                                    value={dados.etapa4.area2}
                                    onChange={handleChange}
                                />
                            </label>
                            <label>
                                <Label toolTipContent=''>
                                    Dosagem(kg/ha):
                                </Label>
                                <input
                                    type="text"
                                    name="dosagem2"
                                    value={dados.etapa4.dosagem2}
                                    onChange={handleChange}
                                />
                            </label>
                        </div>

                        <div className='inputduplo'>
                            <label>
                                <Label toolTipContent=''>
                                    Área(ha):
                                </Label>
                                <input
                                    type="text"
                                    name="area3"
                                    value={dados.etapa4.area3}
                                    onChange={handleChange}
                                />
                            </label>
                            <label>
                                <Label toolTipContent=''>
                                    Dosagem(kg/ha):
                                </Label>
                                <input
                                    type="text"
                                    name="dosagem3"
                                    value={dados.etapa4.dosagem3}
                                    onChange={handleChange}
                                />
                            </label>
                        </div>

                        <div className='inputduplo'>
                            <label>
                                <Label toolTipContent=''>
                                    Área(ha):
                                </Label>
                                <input
                                    type="text"
                                    name="area4"
                                    value={dados.etapa4.area4}
                                    onChange={handleChange}
                                />
                            </label>
                            <label>
                                <Label toolTipContent=''>
                                    Dosagem(kg/ha):
                                </Label>
                                <input
                                    type="text"
                                    name="dosagem4"
                                    value={dados.etapa4.dosagem4}
                                    onChange={handleChange}
                                />
                            </label>
                        </div>

                        <div className='inputduplo'>
                            <label>
                                <Label toolTipContent=''>
                                    Área(ha):
                                </Label>
                                <input
                                    type="text"
                                    name="area5"
                                    value={dados.etapa4.area5}
                                    onChange={handleChange}
                                />
                            </label>
                            <label>
                                <Label toolTipContent=''>
                                    Dosagem(kg/ha):
                                </Label>
                                <input
                                    type="text"
                                    name="dosagem5"
                                    value={dados.etapa4.dosagem5}
                                    onChange={handleChange}
                                />
                            </label>
                        </div>


                    </>
                )}

                {etapa === 5 && (
                    <>
                        {/* Gasolina - Emissões GEE */}
                        <h2>Gasolina - Emissões GEE </h2>
                        <label>
                            <Label toolTipContent=''>
                                Quantidade (L) - Veículo 1
                            </Label>
                            <input
                                type="number"
                                name="H250"
                                value={dados.etapa5.H250}
                                onChange={handleChange}
                            />
                        </label>

                        <label>
                            <Label toolTipContent=''>
                                Quantidade (L) - Veículo 2
                            </Label>
                            <input
                                type="number"
                                name="H251"
                                value={dados.etapa5.H251}
                                onChange={handleChange}
                            />
                        </label>

                        <label>
                            <Label toolTipContent=''>
                                Quantidade (L) - Veículo 3
                            </Label>
                            <input
                                type="number"
                                name="H252"
                                value={dados.etapa5.H252}
                                onChange={handleChange}
                            />
                        </label>

                        <label>
                            <Label toolTipContent=''>
                                Quantidade (L) - Veículo 4
                            </Label>
                            <input
                                type="number"
                                name="H253"
                                value={dados.etapa5.H253}
                                onChange={handleChange}
                            />
                        </label>

                        <label>
                            <Label toolTipContent=''>
                                Quantidade (L) - Veículo 5
                            </Label>
                            <input
                                type="number"
                                name="H254"
                                value={dados.etapa5.H254}
                                onChange={handleChange}
                            />
                        </label>

                        <label>
                            <Label toolTipContent=''>
                                Quantidade (L) - Veículo 6
                            </Label>
                            <input
                                type="number"
                                name="H255"
                                value={dados.etapa5.H255}
                                onChange={handleChange}
                            />
                        </label>

                    </>
                )}

                {etapa === 6 && (
                    <>
                        {/* Algodão */}
                        <h2>Resíduos Agrícolas</h2>
                        <h3>Algodão:</h3>
                        <div className='inputduplo'>
                            <label>
                                <Label toolTipContent=''>
                                    Área (ha)
                                </Label>
                                <input
                                    type="number"
                                    name="algodaoarea"
                                    value={dados.etapa6.algodaoarea}
                                    onChange={handleChange}
                                />
                            </label>
                            <label>
                                <Label toolTipContent=''>
                                    Produtividade (Sacas/@/ha)
                                </Label>
                                <input
                                    type="number"
                                    name="algodaoprodutividade"
                                    value={dados.etapa6.algodaoprodutividade}
                                    onChange={handleChange}
                                />
                            </label>
                        </div>

                        {/* Soja */}
                        <h3>Soja:</h3>
                        <div className='inputduplo'>
                            <label>
                                <Label toolTipContent=''>
                                    Área (ha)
                                </Label>
                                <input
                                    type="number"
                                    name="sojaarea"
                                    value={dados.etapa6.sojaarea}
                                    onChange={handleChange}
                                />
                            </label>
                            <label>
                                <Label toolTipContent=''>
                                    Produtividade (Sacas/@/ha)
                                </Label>
                                <input
                                    type="number"
                                    name="sojaprodutividade"
                                    value={dados.etapa6.sojaprodutividade}
                                    onChange={handleChange}
                                />
                            </label>
                        </div>

                        {/* Milho */}
                        <h3>Milho:</h3>
                        <div className='inputduplo'>
                            <label>
                                <Label toolTipContent=''>
                                    Área (ha)
                                </Label>
                                <input
                                    type="number"
                                    name="milhoarea"
                                    value={dados.etapa6.milhoarea}
                                    onChange={handleChange}
                                />
                            </label>
                            <label>
                                <Label toolTipContent=''>
                                    Produtividade (Sacas/@/ha)
                                </Label>
                                <input
                                    type="number"
                                    name="milhoprodutividade"
                                    value={dados.etapa6.milhoprodutividade}
                                    onChange={handleChange}
                                />
                            </label>
                        </div>

                        {/* Mandioca */}
                        <h3>Mandioca:</h3>
                        <div className='inputduplo'>
                            <label>
                                <Label toolTipContent=''>
                                    Área (ha)
                                </Label>
                                <input
                                    type="number"
                                    name="mandiocaarea"
                                    value={dados.etapa6.mandiocaarea}
                                    onChange={handleChange}
                                />
                            </label>
                            <label>
                                <Label toolTipContent=''>
                                    Produtividade (Sacas/@/ha)
                                </Label>
                                <input
                                    type="number"
                                    name="mandiocaprodutividade"
                                    value={dados.etapa6.mandiocaprodutividade}
                                    onChange={handleChange}
                                />
                            </label>
                        </div>

                        {/* Batata */}
                        <h3>Batata:</h3>
                        <div className='inputduplo'>
                            <label>
                                <Label toolTipContent=''>
                                    Área (ha)
                                </Label>
                                <input
                                    type="number"
                                    name="batataarea"
                                    value={dados.etapa6.batataarea}
                                    onChange={handleChange}
                                />
                            </label>
                            <label>
                                <Label toolTipContent=''>
                                    Produtividade (Sacas/@/ha)
                                </Label>
                                <input
                                    type="number"
                                    name="batataprodutividade"
                                    value={dados.etapa6.batataprodutividade}
                                    onChange={handleChange}
                                />
                            </label>
                        </div>

                        {/* Amendoin */}
                        <h3>Amendoim:</h3>
                        <div className='inputduplo'>
                            <label>
                                <Label toolTipContent=''>
                                    Área (ha)
                                </Label>
                                <input
                                    type="number"
                                    name="amendoinarea"
                                    value={dados.etapa6.amendoinarea}
                                    onChange={handleChange}
                                />
                            </label>
                            <label>
                                <Label toolTipContent=''>
                                    Produtividade (Sacas/@/ha)
                                </Label>
                                <input
                                    type="number"
                                    name="amendoinprodutividade"
                                    value={dados.etapa6.amendoinprodutividade}
                                    onChange={handleChange}
                                />
                            </label>
                        </div>

                    </>
                )}

                {etapa === 7 && (
                    <>
                        <h2>Pecuária</h2>
                        <label>
                            <Label toolTipContent=''>
                                Pecuária (Área ha):
                            </Label>
                            <input
                                type="text"
                                name="areaPecuaria"
                                value={dados.etapa7.areaPecuaria}
                                onChange={handleChange}
                            />
                        </label>

                        <label>
                            <Label toolTipContent=''>
                                Abertura de novas áreas para pecuária nos últimos 12 meses (Área ha):
                            </Label>
                            <input
                                type="text"
                                name="aberturaAreasPecuaria"
                                value={dados.etapa7.aberturaAreasPecuaria}
                                onChange={handleChange}
                            />
                        </label>

                        <label>
                            <Label toolTipContent=''>
                                Desmatamento após 31 de dezembro de 1989 na propriedade (Área ha):
                            </Label>
                            <input
                                type="text"
                                name="desmatamentoAreas"
                                value={dados.etapa7.desmatamentoAreas}
                                onChange={handleChange}
                            />
                        </label>

                        <div className='inputtriplo'>
                            <label>
                                <Label toolTipContent=''>
                                    Sistema de produção:
                                </Label>
                                <input
                                    type="text"
                                    name="sistemaProducao"
                                    value={dados.etapa7.sistemaProducao}
                                    onChange={handleChange}
                                />
                            </label>

                            <label>
                                <Label toolTipContent=''>
                                    Raças:
                                </Label>
                                <input
                                    type="text"
                                    name="racas"
                                    value={dados.etapa7.racas}
                                    onChange={handleChange}
                                />
                            </label>

                            <label>
                                <Label toolTipContent=''>
                                    Atividade específica:
                                </Label>
                                <input
                                    type="text"
                                    name="atividadeEspecifica"
                                    value={dados.etapa7.atividadeEspecifica}
                                    onChange={handleChange}
                                />
                            </label>
                        </div>

                        <div className='inputtriplo'>
                            <label>
                                <Label toolTipContent=''>
                                    Tipos de gramíneas:
                                </Label>
                                <input
                                    type="text"
                                    name="tiposGramineas"
                                    value={dados.etapa7.tiposGramineas}
                                    onChange={handleChange}
                                />
                            </label>

                            <label>
                                <Label toolTipContent=''>
                                    Condição da pastagem:
                                </Label>
                                <input
                                    type="text"
                                    name="condicaoPastagem"
                                    value={dados.etapa7.condicaoPastagem}
                                    onChange={handleChange}
                                />
                            </label>

                            <label>
                                <Label toolTipContent=''>
                                    Pastos separados:
                                </Label>
                                <input
                                    type="text"
                                    name="pastosSeparados"
                                    value={dados.etapa7.pastosSeparados}
                                    onChange={handleChange}
                                />
                            </label>
                        </div>

                        <div className='inputtriplo'>
                            <label>
                                <Label toolTipContent=''>
                                    Área média dos pastos (ha):
                                </Label>
                                <input
                                    type="text"
                                    name="areaMediaPastos"
                                    value={dados.etapa7.areaMediaPastos}
                                    onChange={handleChange}
                                />
                            </label>

                            <label>
                                <Label toolTipContent=''>
                                    Unidade animal/ha ano:
                                </Label>
                                <input
                                    type="text"
                                    name="unidadeAnimalHaAno"
                                    value={dados.etapa7.unidadeAnimalHaAno}
                                    onChange={handleChange}
                                />
                            </label>

                            <label>
                                <Label toolTipContent=''>
                                    Cabeças/ha/ano:
                                </Label>
                                <input
                                    type="text"
                                    name="cabecasHaAno"
                                    value={dados.etapa7.cabecasHaAno}
                                    onChange={handleChange}
                                />
                            </label>
                        </div>

                        <div className='inputtriplo'>
                            <label>
                                <Label toolTipContent=''>
                                    Forragem:
                                </Label>
                                <input
                                    type="text"
                                    name="forragem"
                                    value={dados.etapa7.forragem}
                                    onChange={handleChange}
                                />
                            </label>

                            <label>
                                <Label toolTipContent=''>
                                    Tipo de solo:
                                </Label>
                                <input
                                    type="text"
                                    name="tipoSolo"
                                    value={dados.etapa7.tipoSolo}
                                    onChange={handleChange}
                                />
                            </label>

                            <label>
                                <Label toolTipContent=''>
                                    Calagem e adubação:
                                </Label>
                                <input
                                    type="text"
                                    name="calagemAdubacao"
                                    value={dados.etapa7.calagemAdubacao}
                                    onChange={handleChange}
                                />
                            </label>
                        </div>


                        <label>
                            <Label toolTipContent=''>
                                Análise de fertilidade do solo:
                            </Label>
                            <input
                                type="text"
                                name="analiseFertilidadeSolo"
                                value={dados.etapa7.analiseFertilidadeSolo}
                                onChange={handleChange}
                            />
                        </label>

                        <label>
                            <Label toolTipContent=''>
                                Análises anteriores:
                            </Label>
                            <input
                                type="text"
                                name="analisesAnteriores"
                                value={dados.etapa7.analisesAnteriores}
                                onChange={handleChange}
                            />
                        </label>

                        <label>
                            <Label toolTipContent=''>
                                Amostra composta do solo:
                            </Label>
                            <input
                                type="text"
                                name="amostraCompostaSolo"
                                value={dados.etapa7.amostraCompostaSolo}
                                onChange={handleChange}
                            />
                        </label>

                        <label>
                            <Label toolTipContent=''>
                                Limpeza pastagem por fogo:
                            </Label>
                            <input
                                type="text"
                                name="limpezaPastagemFogo"
                                value={dados.etapa7.limpezaPastagemFogo}
                                onChange={handleChange}
                            />
                        </label>

                        <h2>Composição do Rebanho</h2>
                        {/* Composição do Rebanho */}
                        <div className='inputduplo'>
                            <label>
                                <Label toolTipContent=''>
                                    Machos &gt; 2 anos:
                                </Label>
                                <input
                                    type="text"
                                    name="composicaoRebanho.machosMaisDoisAnos"
                                    value={dados.etapa7.composicaoRebanhomachosMaisDoisAnos}
                                    onChange={handleChange}
                                />
                            </label>

                            <label>
                                <Label toolTipContent=''>
                                    Fêmeas &gt; 2 anos:
                                </Label>
                                <input
                                    type="text"
                                    name="composicaoRebanho.femeasMaisDoisAnos"
                                    value={dados.etapa7.composicaoRebanhofemeasMaisDoisAnos}
                                    onChange={handleChange}
                                />
                            </label>
                        </div>

                        <div className='inputduplo'>
                            <label>
                                <Label toolTipContent=''>
                                    Novilhos (entre 1 e 2 anos):
                                </Label>
                                <input
                                    type="text"
                                    name="composicaoRebanho.novilhosUmDoisAnos"
                                    value={dados.etapa7.composicaoRebanhonovilhosUmDoisAnos}
                                    onChange={handleChange}
                                />
                            </label>

                            <label>
                                <Label toolTipContent=''>
                                    Novilhas (entre 1 e 2 anos):
                                </Label>
                                <input
                                    type="text"
                                    name="composicaoRebanho.novilhasUmDoisAnos"
                                    value={dados.etapa7.composicaoRebanhonovilhasUmDoisAnos}
                                    onChange={handleChange}
                                />
                            </label>
                        </div>

                        <div className='inputduplo'>
                            <label>
                                <Label toolTipContent=''>
                                    Bezerros &lt; 1 ano:
                                </Label>
                                <input
                                    type="text"
                                    name="composicaoRebanho.bezerrosMenosUmAno"
                                    value={dados.etapa7.composicaoRebanhobezerrosMenosUmAno}
                                    onChange={handleChange}
                                />
                            </label>

                            <label>
                                <Label toolTipContent=''>
                                    Bezerras &lt; 1 ano:
                                </Label>
                                <input
                                    type="text"
                                    name="composicaoRebanho.bezerrasMenosUmAno"
                                    value={dados.etapa7.composicaoRebanhobezerrasMenosUmAno}
                                    onChange={handleChange}
                                />
                            </label>
                        </div>


                    </>
                )}

                {etapa === 8 && (
                    <>
                        <h2>Desmatamento</h2>
                        <label>
                            <Label toolTipContent=''>
                                Área Desmatada (ha):
                            </Label>
                            <input
                                type="number"
                                name="areaDesmatada"
                                value={dados.etapa8.areaDesmatada}
                                onChange={handleChange}
                                required
                            />
                        </label>

                        <label>
                            <Label toolTipContent=''>
                                Agricultura (ha):
                            </Label>
                            <input
                                type="number"
                                name="agricultura"
                                value={dados.etapa8.agricultura}
                                onChange={handleChange}
                                required
                            />
                        </label>

                        <label>
                            <Label toolTipContent=''>
                                Pastagem (ha):
                            </Label>
                            <input
                                type="number"
                                name="pastagem"
                                value={dados.etapa8.pastagem}
                                onChange={handleChange}
                                required
                            />
                        </label>

                    </>
                )}

                {etapa === 9 && (
                    <>
                        <h2>Estoque Vegetação Nativa</h2>
                        <label>
                            <Label toolTipContent=''>
                                Reserva Legal (ha):
                            </Label>
                            <input
                                type="number"
                                name="reservaLegal"
                                value={dados.etapa9.reservaLegal}
                                onChange={handleChange}
                                required
                            />
                        </label>

                        <label>
                            <Label toolTipContent=''>
                                Área de Preservação Permanente (ha):
                            </Label>
                            <input
                                type="number"
                                name="app"
                                value={dados.etapa9.app}
                                onChange={handleChange}
                                required
                            />
                        </label>

                        <label>
                            <Label toolTipContent=''>
                                Excedente de Reserva Legal (ha):
                            </Label>
                            <input
                                type="number"
                                name="excedenteRLL"
                                value={dados.etapa9.excedenteRL}
                                onChange={handleChange}
                                required
                            />
                        </label>

                    </>
                )}

                <button type="button" onClick={handlePrev} disabled={etapa === 1}>
                    Anterior
                </button>
                {etapa !== 9 && (
                    <button type="button" onClick={handleNext} disabled={etapa === 9}>
                        Próximo
                    </button>
                )}
                {/* Habilitar o botão Enviar apenas na última etapa */}
                {etapa === 9 && (
                    <button type="submit" onClick={(e) => handleSubmit(e)} >Enviar</button>

                )}


            </form>
        </div>
    );
};

export default Formulario;
