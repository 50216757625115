import styles from './header.module.css'
import logotipo from '../logo.webp'; // Caminho relativo para a imagem

export default function Header() {
  return (
    <header>
      <div className={styles.container}>
        <div className={styles.containerLogo}><img src={logotipo} alt="Descrição da imagem" /></div>
        <div className={styles.containerLinks}>
          <nav>
            <ul className={styles.linksNav}>
              <li><a target='_blank' href="https://ecofix.com.br" rel="noreferrer">Homepage</a></li>
              <li><a target='_blank' href="https://ecofix.com.br/servicos" rel="noreferrer">Serviços</a></li>
              <li><a target='_blank' href="https://ecofix.com.br/sobre" rel="noreferrer">Sobre</a></li>
              <li><a target='_blank' href="https://ecofix.com.br/equipe/" rel="noreferrer">Equipe</a></li>
              <li><a target='_blank' href="https://ecofix.com.br/contato/" rel="noreferrer">Contato</a></li>
            </ul>
          </nav>
          <div className={styles.button}>
            <a href="mailto:contato@ecofix.com.br">Fale conosco</a>
          </div>
        </div>
      </div>
    </header>
  )
}
//contato@ecofix.com.br
